import React from "react"
import { Col, Row } from "react-bootstrap"
import { Link } from "gatsby"
import { faLightbulb, faCloud } from "@fortawesome/free-solid-svg-icons"

import PageLayout from "../../components/PageLayout"
import Hero from "../../components/Hero"
import SEO from "../../components/seo"
import Icon from "../../components/Icon"

import IoTConsultancyIcon from "./images/IoTHeaderIcon.svg"

import { Service } from "./style.module.scss"

const ServicesPage = () => (
  <PageLayout
    title={"Services"}
    hero={
      <Hero
        title="Our Services"
        subtitle="We offer a variety of human-centered technology solutions for businesses large and small."
      />
    }
  >
    <div className="mt-4">
      <SEO
        title="Services"
        description="Human-centered technology solutions for businesses large and small"
      />
      <Row>
        <Col xs={12} lg={6}>
          <div className={Service}>
            <div className="d-flex align-items-start mb-4">
              <Link to="/services/cloud-consultancy">
                <Icon icon={faCloud} />
              </Link>
              <div>
                <Link to="/services/cloud-consultancy">
                  <h3>Cloud Consultancy</h3>
                </Link>
                <p>
                  The cloud does not need to be scary. We can help you no matter
                  where you are on your journey. With expertise in software
                  engineering, human-centric architecture and scaling solutions
                  to meet web-scale demand, we can tailor a solution that suits
                  your business goals.
                </p>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} lg={6}>
          <div className={Service}>
            <div className="d-flex align-items-start mb-4">
              <Link to="/services/iot-consultancy">
                <Icon image={IoTConsultancyIcon} />
              </Link>
              <div>
                <Link to="/services/iot-consultancy">
                  <h3>IoT Consultancy</h3>
                </Link>
                <p>
                  Moving IoT workloads to the cloud provides resilience,
                  reliability and peace of mind that you could only dream of
                  with a hand rolled solution. We have experience designing,
                  building and operating IoT systems at massive scale. Rest
                  assured, you'll be in safe hands with us.
                </p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6}>
          <div className={Service}>
            <div className="d-flex align-items-start mb-4">
              <Link to="/services/rapid-prototyping">
                <Icon icon={faLightbulb} />
              </Link>
              <div>
                <Link to="/services/rapid-prototyping">
                  <h3>Rapid Prototyping</h3>
                </Link>
                <p>
                  Often it's important to prove out ideas and concepts quickly.
                  We are experts in creating prototype mobile apps and cloud
                  applications with minimal direction, using a unique rapid
                  feedback process to deliver value with minimal overhead.
                </p>
              </div>
            </div>
          </div>
        </Col>
        {/* <Col xs={12} lg={6}>
          <div className={Service}>
            <div className="d-flex align-items-start mb-4">
              <Link to="/services/service-design">
              <Icon icon={faLightbulb} />
              </Link>
              <div>
                <Link to="/services/service-design">
                  <h3>Service Design</h3>
                </Link>
                <p>
                  Putting the user first to improve a service end-to-end and
                  front-to-back by considering people, processes, communications
                  and technology. This is how we turn ideas into action.
                </p>
              </div>
            </div>
          </div>
        </Col> */}
      </Row>
    </div>
  </PageLayout>
)

export default ServicesPage
